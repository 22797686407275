<template>
  <div class="box">
    <img src="@/img/vip/no-img.png" class="img" />
    <div class="text">暂未开通VIP权益,您可以</div>
    <van-button round class="button" color="#113FC4" plain to="yl"
      >查看样例</van-button
    >
    <!-- <div class="tag-box">
      <div></div>
    </div> -->
    <van-row style="margin-top: 37px">
      <van-col span="8"
        ><img src="@/img/vip/no-tag1.png" alt="" class="tag-img" />
        <div class="tag-text">准入失败原因可查</div></van-col
      >
      <van-col span="8"
        ><img src="@/img/vip/no-tag2.png" alt="" class="tag-img" />
        <div class="tag-text">企业预授信额度</div></van-col
      >
      <van-col span="8"
        ><img src="@/img/vip/no-tag3.png" alt="" class="tag-img" />
        <div class="tag-text">企业信用分</div></van-col
      >
    </van-row>
    <van-row style="margin-top: 45px">
      <van-col span="12"
        ><img src="@/img/vip/no-tag4.png" alt="" class="tag-img" />
        <div class="tag-text">融资报告每月提醒</div></van-col
      >
      <van-col span="12"
        ><img src="@/img/vip/no-tag5.png" alt="" class="tag-img" />
        <div class="tag-text">企业经营风险提示</div></van-col
      >
    </van-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    toInfo(index) {
      this.$router.push({
        path: "caseInfo",
        query: { index: index },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: url("../../img/vip/no-bg.png") no-repeat;
  background-size: 100%;
  min-height: calc(100vh - 80px);
  // display: flex;
  // justify-content: center;
  // flex-wrap: wrap;
  padding-top: 80px;
  text-align: center;
  .img {
    width: 239px;
    height: 172px;
  }
  .text {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #113fc4;
    margin: 15px;
  }
  .button {
    width: 200px;
    height: 40px;
    font-size: 14px;
  }
  .tag-img {
    width: 43px;
    height: 43px;
  }
  .tag-text {
    font-size: 10px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #999999;
  }
}
</style>
